import * as React from "react";
import { Styling } from "../styles/pages/about.styled";
import HomeIntro from "../components/page-components/Homepage/HomeIntro";
import { graphql } from "gatsby";
// markup
const ThanksSigningUp = ({ data }) => {
  console.log(data);
  return (
    <Styling>
      <HomeIntro
        centeredText={true}
        isHome={true}
        background={data.wpPage.homepage.heroBlock.background}
        backgroundImage={data.wpPage.homepage.heroBlock.backgroundImage}
        heading={"Thanks for signing up"}
        backgroundVideo={data.wpPage.homepage.heroBlock.backgroundVideo}
      />
    </Styling>
  );
};

export default ThanksSigningUp;

export const query = graphql`
  {
    wpPage(title: { eq: "Homepage" }) {
      seo {
        metaDesc
        title
      }
      homepage {
        heroBlock {
          background
          heading
          backgroundVideo
          backgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 3000, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    }
  }
`;
